/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import HolidaysList from "./HolidaysList";
import holidayManager from "services/Holiday/holidayManager";
import { Row } from "components/DataTable";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";

export default function Holidays() {
  const [holidays, setHolidays] = useState<Row[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const tableColumns = [
    // { key: "id", label: `Id`, bold: true },
    {
      key: "holidayDate",
      label: `${t("holiday.holidayDate")}`,
      type: "Date",
      bold: true,
    },
    { key: "occasion", label: `${t("holiday.occassion")}` },
    { key: "day", label: `${t("holiday.day")}`, bold: true },
    { key: "year", label: `${t("holiday.year")}` },
    { key: "location", label: `${t("holiday.location")}`, bold: true },
  ];

  useEffect(() => {
    setLoading(true);
    holidayManager
      .getHolidayList(new Date().getFullYear())
      .then((res) => {
        const holidayResponses: Row[] = res.data.map((row) => ({
          id: row.holidayId,
          ...row,
        }));
        holidayResponses.sort(
          (a: any, b: any) => new Date(a.holidayDate).getTime() - new Date(b.holidayDate).getTime()
        );

        console.log("holidayResponses ", holidayResponses);
        setHolidays(holidayResponses);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching holiday list:", error);
        setLoading(false);
      });
  }, []);
  const BreadCrumbList = [
    { name: "Holiday", link: "#" },
    { name: "list", link: "#" },
  ];

  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />

      <HolidaysList data={holidays} columns={tableColumns} loading={loading} />
    </>
  );
}
