import {
  FaCalendarTimes,
  FaCity,
  // FaTasks,
  FaAddressCard,
  FaCalendarAlt,
  FaFileInvoiceDollar,
  FaBusinessTime,
  FaCloudUploadAlt,
  FaUsers,
  FaBook,
} from "react-icons/fa";

const items = [
  {
    label: "Inventory",
    url: "inventories",
    icon: FaCity,
    roles: [1, 2, 3],
    // submenu: [
    //   {
    //     label: "Inventory",
    //     url: "inventories",
    //     icon: FaCity,
    //   },
    //   {
    //     label: "Rented",
    //     url: "rented",
    //     icon: FaCity,
    //   },
    //   {
    //     label: "Sample",
    //     url: "sample",
    //     icon: FaCity,
    //   },
    // ],
  },
  {
    label: "Employees",
    url: "employees",
    icon: FaUsers,
    roles: [2],
  },
  {
    label: "Projects",
    url: "projects",
    icon: FaBook,
    roles: [2],
  },
  {
    label: "My Profile",
    url: "profile",
    icon: FaAddressCard,
    roles: [1, 2, 3],
  },
  // {
  //   label: "Tasks",
  //   url: "tasks",
  //   icon: FaTasks,
  //   roles: [1, 2],
  // },

  {
    label: "Holiday",
    url: "holidays",
    icon: FaCalendarAlt,
    roles: [1, 2, 3],
  },
  {
    label: "Reimbursement",
    url: "reimbursement",
    icon: FaFileInvoiceDollar,
    roles: [1, 2, 3],
  },
  {
    label: "Leave",
    url: "leaves",
    icon: FaCalendarTimes,
    roles: [1, 2, 3],
  },
  {
    label: "Time Sheet",
    url: "timesheet",
    icon: FaBusinessTime,
    roles: [1, 2],
  },
  {
    label: "View Time Sheet",
    url: "view-timesheet",
    icon: FaBusinessTime,
    roles: [2],
  },
  {
    label: "Export Time Sheet",
    url: "export-timesheet",
    icon: FaBusinessTime,
    roles: [2],
  },
  {
    label: "Generate Docs",
    url: "generate-docs",
    icon: FaBusinessTime,
    roles: [2],
  },
  {
    label: "Upload",
    url: "upload",
    icon: FaCloudUploadAlt,
    roles: [2],
  },
];

export default items;

// Sample Submenu
// {
//   label: "Sample",
//   url: "sample",
//   icon: FaPodcast,
//   submenu: [
//     {
//       label: "Products",
//       url: "products",
//       icon: FaProjectDiagram,
//     },
//     {
//       label: "Orders",
//       url: "orders",
//       icon: FaPencilRuler,
//     },
//   ],
// },
