/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "components/Input";
import apiService from "services/apiService";
import { capitalize } from "util/Util";
import Modal from "components/Modal";
import NoDataImage from "assets/nodata.jpg";
import UploadDocs from "./UploadDocs";
import appConfig from "services/apiConfig";
import { MdDelete } from "react-icons/md";
import { useToaster } from "contexts/toasterContext";

const schema = yup.object({});

export default function GenerateDocs() {
  const [dynamicSchema, setDynamicSchema] = useState<any>(yup.object().shape({}));
  const methods = useForm({
    resolver: yupResolver(dynamicSchema),
  });
  const [tags, setTags] = useState<string[]>([]);
  const [docId, setDocId] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const { reset } = methods;
  const [refresh, setRefresh] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { addToast } = useToaster();
  useEffect(() => {
    if (tags.length > 0) {
      const newSchema = schema.shape(
        tags.reduce((acc: any, tag: string) => {
          acc[tag] = yup.string().required(`${tag.split("::")[0]} is required`);
          return acc;
        }, {})
      );
      setDynamicSchema(newSchema);
    }
  }, [tags]);

  useEffect(() => {
    apiService
      .get("api/documents/templates")
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [refresh]);

  const handleGenerateDocument = async (data: any) => {
    console.log("data==>", data);
    data.format = "docx";
    const format = "docx";
    const url = appConfig.getBaseUrl();
    const token = localStorage.getItem("accessToken");
    try {
      const queryString = new URLSearchParams(data);
      const response = await fetch(`${url}api/documents/generateDocument/${docId}?${queryString}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        alert(`Failed to generate document: ${errorText}`);
        return;
      }
      const contentType: any = response.headers.get("Content-Type");
      const blob = await response.blob();
      let mimeType;
      if (contentType.includes("pdf")) {
        mimeType = "application/pdf";
      } else if (contentType.includes("wordprocessingml.document")) {
        mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else {
        mimeType = "application/octet-stream";
      }

      const a = document.createElement("a");
      a.href = URL.createObjectURL(new Blob([blob], { type: mimeType }));
      a.download = `document.${format}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while generating the document.");
    }
  };

  const getTypeFromTag = (tag: string): "text" | "number" | "date" => {
    const type = tag.split("::")[1];

    switch (type) {
      case "String":
        return "text";
      case "Date":
        return "date";
      case "int":
        return "number";
      default:
        return "text";
    }
  };

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDoc = data.find((doc) => doc.id === parseInt(e.target.value));
    if (selectedDoc) {
      setTags(selectedDoc.tags);
      setDocId(selectedDoc.id);
      reset();
    }
  };

  const handleDeleteDocs = () => {
    setIsDeleting(true);
    apiService
      .delete(`api/documents/${docId}`)
      .then((res) => {
        console.log("res", res);
        setTags([]);
        setDocId(null);
        addToast(res.data, "success");
      })
      .catch((err) => {
        console.log("err", err);
        addToast("Something Went Wrong", "error");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="w-300 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white  bg-blue-700 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={() => {
              setOpenModal(true);
              setDynamicSchema(schema);
              setTags([]);
            }}
          >
            Upload Document
          </button>
        </div>
        {openModal && (
          <Modal
            isOpen={openModal}
            onClose={() => {
              setOpenModal(false);
              setTags([]);
              setDynamicSchema("");
            }}
            header="Upload Document"
          >
            <UploadDocs refresh={refresh} setRefresh={setRefresh} setOpenModal={setOpenModal} />
          </Modal>
        )}
        <div>
          <div className="p-2 space-y-4 md:space-y-6 ">
            <h2 className="block text-xl font-medium text-center text-blue-700 dark:text-gray-300">Generate Docs</h2>
            <hr className="mb-2" />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "66%" }}>
                <div className="center">
                  <div style={{ maxWidth: 540 }}>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Select a Document Template
                    </label>
                    <select
                      className="block w-full p-2 mb-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={handleTemplateChange}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Select a Document Template
                      </option>
                      {data.map((doc: any) => (
                        <option key={doc.id} value={doc.id}>
                          {doc.documentName}
                        </option>
                      ))}
                    </select>
                  </div>
                  {docId && (
                    <div
                      className="flex items-center justify-between p-2 m-1 mt-3  bg-gray-100 rounded-lg shadow-md"
                      style={{ margin: "8px", marginTop: "24px" }}
                    >
                      <button
                        onClick={handleDeleteDocs}
                        disabled={isDeleting}
                        className="ml-4 text-red-500 hover:text-red-700 focus:outline-none"
                        aria-label="Delete"
                      >
                        <MdDelete size={24} />
                      </button>
                    </div>
                  )}
                </div>
                {tags && tags.length > 0 ? (
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleGenerateDocument)} className="center">
                      <div
                        style={{ maxWidth: 540 }}
                        className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 p-6 space-y-4 md:space-y-6 sm:p-8"
                      >
                        {tags.map((tag: any) => (
                          <Input
                            key={tag}
                            name={tag}
                            label={capitalize(tag.split("::")[0])}
                            required
                            placeholder={`Please enter ${tag.split("::")[0]}`}
                            type={getTypeFromTag(tag)}
                          />
                        ))}
                        <div className="mt-6">
                          <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Generate Document
                          </button>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                ) : (
                  <div className="center">
                    <img src={NoDataImage} height={300} width={400} alt="No data" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
