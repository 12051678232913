import { AxiosResponse } from "axios";
import apiService from "services/apiService";
import { AddTaskPayload, GetTaskResponse, UpdateTaskPayload } from "./taskInterface";

class TaskManager{
    private url: string;
    constructor() {
        this.url = 'api/task'
    }

    async getAllTasks(): Promise<AxiosResponse<Array<GetTaskResponse>>> {
        return await apiService.get(`${this.url}/getAll`);
    }

    async addTask(payload: AddTaskPayload): Promise<AxiosResponse> {
        return await apiService.post(`${this.url}/add`, payload)
    }

    async updateTask(payload: UpdateTaskPayload): Promise<AxiosResponse> {
        return await apiService.put(`${this.url}/update`, payload);
    }

    async deleteTask(id: number): Promise<AxiosResponse> {
        return await apiService.delete(`${this.url}/${id}`);
    }
}

const taskManager = new TaskManager();
export default taskManager;