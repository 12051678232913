/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  FaUser,
  FaCalendarAlt,
  FaDollarSign,
  FaCommentDots,
  FaUserShield,
  FaInfoCircle,
  FaFileExcel,
  FaFileAlt,
  FaDownload,
} from "react-icons/fa";
import formatCurrency from "util/currency";
import { dateFormate } from "util/Util";

const InfoView: React.FC<any> = (props) => {
  const {
    type,
    description,
    comment,
    status,
    username,
    admin,
    submitDate,
    spentDate,
    submitAmount,
    approveAmount,
    differenceAmount,
    unit,
    data,
    dataType,
    dataOriginalName,
  } = props;

  const statusColors: any = {
    INITIATED: "bg-yellow-200 text-yellow-800",
    APPROVED: "bg-green-200 text-green-800",
    REJECTED: "bg-red-200 text-red-800",
  };

  const getFileTypeIcon = (dataType?: string) => {
    if (!dataType) return null;

    const downloadFile = () => {
      const link = document.createElement("a");
      link.href = data;
      link.download = dataOriginalName || "download";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    switch (dataType.toLowerCase()) {
      case "image/jpeg":
      case "image/png":
      case "image/svg+xml":
        return (
          <div>
            <img
              className="w-full object-contain cursor-pointer"
              src={data}
              alt={dataOriginalName}
              loading="lazy"
              style={{ maxHeight: 500 }}
            />
            <a
              href={data}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-2 flex w-full	 items-center justify-center text-indigo-600 hover:text-indigo-800"
              download
            >
              <FaDownload className="mr-2" />
              Download Image
            </a>
          </div>
        );
      case "application/pdf":
        return (
          <div>
            <embed
              className="h-auto w-full"
              src={data}
              type="application/pdf"
              style={{
                minHeight: "80%",
                minWidth: "100%",
              }}
            />
            <a
              href={data}
              target="blank"
              className="mt-2 flex w-full	 items-center justify-center text-indigo-600 hover:text-indigo-800"
              download
            >
              <FaDownload className="mr-2" />
              Download Pdf
            </a>
          </div>
        );
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return (
          <div>
            <div className="text-6xl text-green-500">
              <FaFileExcel />
            </div>
            <button
              className="mt-2 flex items-center justify-center text-indigo-600 hover:text-indigo-800"
              onClick={downloadFile}
            >
              <FaDownload className="mr-2" />
              Download Excel
            </button>
          </div>
        );
      default:
        return (
          <div>
            <div className="text-6xl text-gray-500">
              <FaFileAlt />
            </div>
            <button
              className="mt-2 flex items-center justify-center text-indigo-600 hover:text-indigo-800"
              onClick={downloadFile}
            >
              <FaDownload className="mr-2" />
              Download File
            </button>
          </div>
        );
    }
  };

  return (
    <div className="mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-all hover:scale-105 hover:shadow-xl">
      <div className="flex">
        <div className="flex justify-center bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-4 w-full h-auto">
          {getFileTypeIcon(dataType)}
        </div>
        <div className="p-6 bg-gray-50 flex-grow w-full h-auto" style={{ minHeight: 475 }}>
          <div className="text-2xl font-semibold text-indigo-600">{type}</div>
          <div
            className={`mt-2 inline-block rounded-full px-3 py-1 text-sm font-semibold ${statusColors[status] || "bg-gray-200 text-gray-800"}`}
          >
            {status}
          </div>
          <div className="grid grid-cols-2 gap-6 mt-6">
            <div className="col-span-1 flex items-center">
              <FaUser className="text-gray-600 mr-2" />
              <div>
                <p className="text-gray-600">Submitted by</p>
                <p className="text-gray-800 font-medium">{username}</p>
              </div>
            </div>
            <div className="col-span-1 flex items-center">
              <FaCalendarAlt className="text-gray-600 mr-2" />
              <div>
                <p className="text-gray-600">Submit Date</p>
                <p className="text-gray-800 font-medium">{dateFormate(submitDate)}</p>
              </div>
            </div>
            <div className="col-span-1 flex items-center">
              <FaCalendarAlt className="text-gray-600 mr-2" />
              <div>
                <p className="text-gray-600">Spent Date</p>
                <p className="text-gray-800 font-medium">{dateFormate(spentDate)}</p>
              </div>
            </div>
            {comment && (
              <div className="col-span-2 flex items-center">
                <FaCommentDots className="text-gray-600 mr-2" />
                <div>
                  <p className="text-gray-600">Comment</p>
                  <p className="text-gray-800 font-medium">{comment}</p>
                </div>
              </div>
            )}
            {admin && (
              <div className="col-span-1 flex items-center">
                <FaUserShield className="text-gray-600 mr-2" />
                <div>
                  <p className="text-gray-600">Admin</p>
                  <p className="text-gray-800 font-medium">{admin}</p>
                </div>
              </div>
            )}
            <div className="col-span-1 flex items-center">
              <FaDollarSign className="text-gray-600 mr-2" />
              <div>
                <p className="text-gray-600">Submit Amount</p>
                <p className="text-gray-800 font-medium">{formatCurrency(submitAmount, unit)}</p>
              </div>
            </div>
            {approveAmount !== null && (
              <div className="col-span-1 flex items-center">
                <FaDollarSign className="text-gray-600 mr-2" />
                <div>
                  <p className="text-gray-600">Approve Amount</p>
                  <p className="text-gray-800 font-medium">{formatCurrency(approveAmount, unit)}</p>
                </div>
              </div>
            )}
            {differenceAmount !== null && (
              <div className="col-span-1 flex items-center">
                <FaInfoCircle className="text-gray-600 mr-2" />
                <div>
                  <p className="text-gray-600">Difference Amount</p>
                  <p className="text-gray-800 font-medium">{formatCurrency(differenceAmount, unit)}</p>
                </div>
              </div>
            )}

            <div className="col-span-2 flex items-center">
              <FaCommentDots className="text-gray-600 mr-2" />
              <div>
                <p className="text-gray-600">Description</p>
                <p className="text-gray-800 font-medium">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoView;
