/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "components/Input";
import inventoryManager from "services/Inventory/inventoryManager";
import apiService from "services/apiService";
import Select from "react-select";
import Loader from "components/Loader";
import { inventoryOptions } from "./inventoryValues";
import { sortByKey } from "util/sortOptions";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useToaster } from "contexts/toasterContext";
import { IoMdClose } from "react-icons/io";

interface OptionProps {
  value: string;
  label: string;
}
const schema = yup.object().shape({
  assign: yup.string().required("Assign is required"),
  type: yup.string().required("Type is required"),
  status: yup.string().required("Status is required"),
  owner: yup.string().required("Owner is required"),
  location: yup.string().required("Location is required"),
  identity: yup.string().required("Identity is required"),
  model: yup.string().required("Model is required"),
  brand: yup.string().required("Brand is required"),
  assignDate: yup.date().required("Assign Date is required"),
  purchaseDate: yup.date().required("Purchase Date is required"),
  // validityFrom: yup.date().required("Date is required"),
  // validityTo: yup.date().required(" Date is required"),
  releaseDate: yup.date().required("Release Date is required"),
  asset_type: yup.string().required("Inventorytype is required"),
  userEmail: yup.string().required("User Email is required"),
  cost: yup.number().when("asset_type", {
    is: (val: string) => val === "Own",
    then: (schema) =>
      schema
        .required("Cost Value is required")
        .typeError("Please enter a valid number for Cost Value")
        .min(0, "Cost must be a non-negative number"),
    otherwise: (schema) => schema.notRequired(),
  }),
  depreciationRate: yup.number().when("asset_type", {
    is: (val: string) => val === "Own",
    then: (schema) =>
      schema
        .required("Depreciation Rate is required")
        .typeError("Please enter a valid number for Depreciation Rate")
        .min(0, "Depreciation Rate must be a non-negative number"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rentAmount: yup.number().when("asset_type", {
    is: (val: string) => val === "Rental",
    then: (schema) =>
      schema
        .required("Rental Amount is required")
        .typeError("Please enter a valid number for rent amount")
        .min(0, "Rent Amount must be a non-negative number"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rentStartDate: yup.string().when("asset_type", {
    is: (val: string) => val === "Rental",
    then: (schema) => schema.required("Assign Date is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  issueDate: yup.string().when("asset_type", {
    is: (val: string) => val === "Sample",
    then: (schema) => schema.required("Issued Date is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  returnDate: yup.string().when("asset_type", {
    is: (val: string) => val === "Sample",
    then: (schema) => schema.required("Return Date is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
interface editProductProps {
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRefresh: any;
  refresh: number;
}
const data2 = [
  {
    value: "Own",
    label: "Own",
  },
  {
    value: "Rental",
    label: "Rental",
  },
  {
    value: "Sample",
    label: "Sample",
  },
];
const EditInventory = ({ onClose, data, setRefresh, refresh }: editProductProps) => {
  const methods = useForm({ resolver: yupResolver(schema), defaultValues: data });
  const [option, setOption] = useState("");
  const [data1, setData] = useState<OptionProps[]>();
  const [selected, setSelected] = useState<OptionProps>();
  const [loading, setLoading] = useState(false);
  const [device, setDevice] = useState("");
  const [step, setStep] = useState(0);
  const [images, setImages] = useState<File[]>(data.urls || []);
  // const [errorMessage, setErrorMessage] = useState("");
  // const [selectedUser, setSelectedUser] = useState<any>();
  const [assetType, setAssetType] = useState<any>();
  const { addToast } = useToaster();

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  // const handleImageUpload = (files: FileList | null) => {
  //   if (files) {
  //     const fileArray = Array.from(files);
  //     if (fileArray.length + images.length <= 3) {
  //       setImages((prevImages) => [...prevImages, ...fileArray]);
  //       setErrorMessage("");
  //     } else {
  //       setErrorMessage("You can upload a maximum of 3 images.");
  //     }
  //   }
  // };
  const removeImage = (objectKey: any) => {
    console.log("Removing image with objectKey:", objectKey);
    setImages((prevImages) => prevImages.filter((image: any) => image.objectKey !== objectKey));
    apiService
      .delete(`api/assets/${objectKey}/${data.id}`)
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const addImage = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const file = event.target.files[0];
      if (file) {
        const formdata = new FormData();
        formdata.append("files", file, file.name);
        apiService
          .post(`api/assets/${data.id}/file`, formdata)
          .then((res) => {
            setImages(res.data.urls);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit: SubmitHandler<any> = (data: any) => {
    const DeviceType = data.asset_type;
    data.urls = images;
    const updatedData = { ...data };

    if (DeviceType === "Own") {
      updatedData.ownedDevice = {
        cost: data.cost,
        depreciationRate: data.depreciationRate,
      };
    } else if (DeviceType === "Rental") {
      updatedData.rentedDevice = {
        rentStartDate: data.rentStartDate,
        rentAmount: data.rentAmount,
      };
    } else if (DeviceType === "Sample") {
      updatedData.sampleDevice = {
        issueDate: data.issueDate,
        returnDate: data.returnDate,
      };
    }
    console.log("updatedData :>> ", updatedData);
    inventoryManager
      .updateInventory(updatedData)
      .then((res) => {
        console.log("res :>> ", res);
        setRefresh(refresh + 1);
        onClose();
        addToast("Inventory Updated Successfully", "success");
      })
      .catch((err) => {
        console.log("err :>> ", err);
        addToast("Something Went Wrong", "error");
      });
  };

  const steps = [
    <div key="step1" className="grid gap-4 grid-cols-3">
      <Input name="model" label="Model" placeholder="Enter Model" />
      <Input name="brand" label="Brand" placeholder="Enter Brand" />
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Device Type</label>
        <Select
          {...methods.register("type", { required: true })}
          options={sortByKey(inventoryOptions, "label")}
          placeholder="Device Type"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption: any) => {
            if (selectedOption) {
              methods.setValue("type", selectedOption.value);
              setDevice(selectedOption.value);
            }
          }}
          value={inventoryOptions.find((opt) => opt.value === device)}
          defaultValue={inventoryOptions.find((opt) => opt.value === device)}
        />
        {methods.formState.errors.type && (
          <span className="text-red-500 text-sm mt-1">{methods.formState.errors.type.message as string}</span>
        )}
      </div>
      <Input name="location" label="Location" placeholder="Enter Location" />
      <Input name="status" label="Status" placeholder="Enter Status" />
      <Input name="owner" label="Owner" placeholder="Enter Owner Name" />
      <Input name="assignDate" type="date" label="Assign Date" placeholder="Enter Assign Date" />

      <Input name="assetPassword" label="Asset Password" placeholder="Enter Asset password" />
      <Input name="userEmail" label="User Email" type="email" placeholder="Enter User Email" disabled />
    </div>,
    <div key="step2" className="grid gap-4 grid-cols-3">
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Assign To</label>
        <Select
          {...methods.register("assign", { required: true })}
          options={data1}
          placeholder="Select Employee"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption) => {
            if (selectedOption) {
              methods.setValue("assign", selectedOption.label);
              methods.setValue("userEmail", selectedOption.value);
              // setSelectedUser(selectedOption);
              setSelected(selectedOption);
            }
          }}
          value={selected}
        />
        {methods.formState.errors.assign && (
          <span className="text-red-500 text-sm mt-1">{methods.formState.errors.assign.message as string}</span>
        )}
      </div>

      <Input name="purchaseDate" type="date" label="Purchase Date" placeholder="Enter Purchase Date" />
      <Input name="releaseDate" type="date" label="Release date" placeholder="Enter Release date" />
      <Input name="identity" type="text" label="Inventory Identity Number" placeholder="Enter Identity Number" />
      <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Asset Type</label>
        <Select
          {...methods.register("asset_type", { required: true })}
          options={data2}
          placeholder="Asset Type"
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          onChange={(selectedOption) => {
            if (selectedOption) {
              methods.setValue("asset_type", selectedOption.value);
              setOption(selectedOption.value);
              setAssetType(selectedOption);
              setOption(selectedOption.value);
            }
          }}
          value={assetType}
          defaultValue={data2.find((opt) => opt.value === option)}
        />
        {methods.formState.errors.asset_type && (
          <span className="text-red-500 text-sm mt-1">{methods.formState.errors.asset_type.message as string}</span>
        )}
      </div>
      {option === "Sample" && (
        <>
          <Input name="issueDate" type="date" label="Issue Date" />
          <Input name="returnDate" type="date" label="Return Date" />
        </>
      )}
      {option === "Own" && (
        <>
          <Input name="cost" type="number" label="Cost" placeholder="Eg: 5000" />
          <Input name="depreciationRate" type="number" label="Depreciation Rate" placeholder="Eg: 10.0" />
        </>
      )}
      {option === "Rental" && (
        <>
          <Input name="rentAmount" type="number" label="Rental Amount" placeholder="Eg: 5000" />
          <Input name="rentStartDate" type="date" label="Rent Start Date" />
        </>
      )}
      {/* <div className="mb-4">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload Images</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => handleImageUpload(e.target.files)}
          className="w-full rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
        {errorMessage && <span className="text-red-500 text-sm mt-1">{errorMessage}</span>}
      </div> */}

      <div className="flex space-x-4 justify-center">
        {images &&
          images.map((image: any, index: number) => (
            <div key={index} className="relative">
              <img
                src={image.url}
                alt={`preview-${index}`}
                className="w-36 h-24 object-cover rounded-md"
                loading="lazy"
              />
              <IoMdClose
                onClick={() => removeImage(image.objectKey)}
                className="cursor-pointer absolute top-0 p-1 right-0  text-black rounded-full w-6 h-6 flex items-center justify-center"
                style={{ backgroundColor: "snow" }}
              />
            </div>
          ))}
        {images.length < 3 && (
          <div
            className="w-36 h-24 flex items-center justify-center border border-dashed border-gray-400 rounded-md cursor-pointer"
            onClick={() => document.getElementById("file-input")?.click()}
          >
            <input
              id="file-input"
              type="file"
              accept="image/jpeg,image/jpg,image/png,image/bmp,image/tiff"
              onChange={addImage}
              className="opacity-0 relative inset-0 w-36 h-24 cursor-pointer"
              onClick={(e) => e.stopPropagation()}
            />
            <AiOutlinePlusSquare
              className="text-gray-400 w-6 h-6 absolute"
              // onClick={() => document.getElementById("file-input")?.click()}
            />
          </div>
        )}
      </div>
      <div className="flex justify-center">
        {!isEmpty(methods.formState.errors) && (
          <span className="text-red-500 text-sm mt-1">Please Check Mandatory Feilds </span>
        )}
      </div>
    </div>,
  ];
  useEffect(() => {
    setLoading(true);
    apiService
      .get("api/users")
      .then((res) => {
        // console.log("usersss", res.data);
        const getEmailOptions = (emailArray: any[]) => {
          return emailArray.map((email: any) => ({
            value: email.email,
            label: email.displayName,
          }));
        };
        const emailOptions = getEmailOptions(res.data);
        setData(emailOptions);
        const setUserEmail = emailOptions && emailOptions.find((option) => option.label === data.assign);
        setSelected(setUserEmail);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    methods.setValue("assignDate", new Date(data.assignDate).toISOString().split("T")[0]);
    methods.setValue("purchaseDate", new Date(data.purchaseDate).toISOString().split("T")[0]);
    methods.setValue("releaseDate", new Date(data.releaseDate).toISOString().split("T")[0]);
    methods.setValue("type", data.type);
    setOption(data.asset_type);
    setDevice(data.type);
  }, []);
  useEffect(() => {
    const DeviceType = data.asset_type;
    if (DeviceType === "Own") {
      methods.setValue("cost", data.ownedDevice.cost);
      methods.setValue("depreciationRate", data.ownedDevice.depreciationRate);
    } else if (DeviceType === "Rental") {
      methods.setValue("rentStartDate", new Date(data.rentedDevice.rentStartDate).toISOString().split("T")[0]);
      methods.setValue("rentAmount", data.rentedDevice.rentAmount);
    } else if (DeviceType === "Sample") {
      methods.setValue("issueDate", new Date(data.sampleDevice.issueDate).toISOString().split("T")[0]);
      methods.setValue("returnDate", new Date(data.sampleDevice.returnDate).toISOString().split("T")[0]);
    }
  }, []);

  // const defaultOption = data1.find((res) => res.value === option);
  // console.log("methods.errors", methods);
  return (
    <div className="bg-white  w-full  rounded-md" style={{ width: 900 }}>
      <FormProvider {...methods}>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {steps[step]}
            <div className="flex justify-center	 mt-4">
              {step > 0 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="bg-gray-500 mr-2 text-white px-4 py-2 rounded-md hover:bg-gray-600 focus:outline-none focus:ring focus:border-gray-300"
                >
                  Back
                </button>
              )}
              {step < steps.length - 1 && (
                <button
                  type="button"
                  onClick={nextStep}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                >
                  Next
                </button>
              )}
              {step === steps.length - 1 && (
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        )}
      </FormProvider>
    </div>
  );
};

export default EditInventory;
