/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { reimbursementType, reimbursementUnitOption } from "./ReimbursementInterface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import Input from "components/Input";
import reimbursementManager from "services/Reimbursement/reimbursementManager";

const validationSchema = yup.object({
  reimbursementType: yup.string().required("Leave From is required"),
  inputFile: yup.mixed().required("File is required").nullable(),
  // .test("fileSize", "File size is too large, maximum 1MB allowed", (value: any) => {
  //   if (!value) return true;
  //   return value && value.size <= 1048576;
  // }),
  amount: yup.number().positive().required("Amount is required").typeError("Please enter a valid number "),
  unit: yup.string().required("Days is required"),
  
  submitDate: yup.string().required("Submit date is required"),
  spentDate: yup.string().required("Spend date is required")
  .test("isLess then", "spend Date should be Less Than or equal to submit Date" ,  function (inputVal) {
      
     const { submitDate } = this.parent; 

     if( new Date(inputVal) > new Date(submitDate))
     {
      console.log("inside greater condition"+inputVal);
      return false
     }else if(new Date(inputVal) <= new Date(submitDate))
     {
      console.log("inside less condition : "+ inputVal)
      return true
     }
      return !submitDate || new Date(inputVal) < new Date(submitDate);
      

  }),
  description: yup.string().required("Description is required"),
});

interface IProps {
  onClose: () => void;
  onAddSuccess: (message: string) => void;
  onAddError: (message: string) => void;
}

const AddReimbursement = ({ onClose, onAddSuccess, onAddError }: IProps) => {
  const [imbursementType, setReimbursementType] = useState("Office");
  const [imbursementUnit, setReimbursementUnit] = useState("INR");
  const [file, setFile] = useState<File | null | Blob>(null);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: {
    //   inputFile: {},
    // },
  });

  
    // "todayDate" variable it holds the current date, 
    // And this is used as a maximum date spendDate input field / Avoid entering future dates in Spend Date field . 

     const todayDate = new Date().toISOString().split("T")[0];

  const onReimbursementChange = (selectedOption: any) => {
    setReimbursementType(selectedOption.value);
    methods.setValue("reimbursementType", selectedOption.value);
  };

  const onReimbursementUnitChange = (selectedOption: any) => {
    setReimbursementUnit(selectedOption.value);
    methods.setValue("unit", selectedOption.value);
  };

  const handleInputFileChange = (e: any) => {
    const file: File = e.target.files[0];
    setFile(file);
  };

  const onSubmit = (data: any) => {
    console.log("data", data);

    const itemData = {
      type: data.reimbursementType,
      dataType: file ? file?.type : null,
      description: data.description,
      unit: data.unit,
      submitAmount: data.amount,
      spentDate: data.spentDate,
      submitDate: data.submitDate,
    };
    const dto_object = new Blob([JSON.stringify(itemData)], {
      type: "application/json",
    });
    const formData = new FormData();
    if (file) {
      formData.append("data", file);
    }
    formData.append("reimbursement", dto_object);
    reimbursementManager
      .addReimbursement(formData)
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          onAddSuccess("Added Successfully");
        } else {
          onAddError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
        onAddError("Something went wrong");
      })
      .finally(() => {
        onClose();
      });
  };

  useEffect(() => {
    methods.setValue("reimbursementType", imbursementType || methods.getValues().reimbursementType);
    methods.setValue("unit", imbursementUnit);
    methods.setValue("submitDate", new Date().toISOString().split("T")[0]);
  }, [methods]);

  return (
    <div className="space-y-6 md:grid-cols-2">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-group grid grid-rows-5 gap-6">
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Reimbursement Type <span className="text-red-500"> *</span>
                </label>
                <Select
                  options={reimbursementType}
                  className="select"
                  value={reimbursementType.find((op) => op.value === imbursementType)}
                  onChange={onReimbursementChange}
                  name="reimbursementType"
                />
              </div>

              {/* here is adding required and asterisks sign for Reimbursement file field*/}
              <div className="flex flex-col">
                <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Reimbursement File <span className="text-red-500">*</span>
                </label>
                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  type="file"
                  required
                  accept="image/jpeg,image/jpg,image/png,image/bmp,image/tiff,application/pdf"
                  {...methods.register("inputFile", {
                    onChange: handleInputFileChange,required:true
                  })}
                />
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">
                  PNG, JPG or PDF.
                </p>
                {methods.formState.errors.inputFile && (
                  <span className="text-red-500 text-sm mt-1">{methods.formState.errors.inputFile.message}</span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                <Input type="number" label="Amount" name="amount" required/>
              </div>
              <div className="flex flex-col">
                <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Amount unit <span className="text-red-500"> *</span>
                </label>
                <Select
                  className="select"
                  name="unit"
                  options={reimbursementUnitOption}
                  onChange={onReimbursementUnitChange}
                  value={reimbursementUnitOption.find((op) => op.value === imbursementUnit)}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div className="flex flex-col">
                <Input type="Date" name="spentDate" label="Spent Date" max={todayDate} required/>
              </div>
              <div className="flex flex-col">
                <Input
                  type="Date"
                  name="submitDate"
                  label="Submit Date"
                  disabled
                  value={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-4 gap-y-2">
              <div className="flex flex-col">
                <Input type="text" placeholder="Add Description" name="description" label="Reimbursement Description" required/>
              </div>
            </div>
            <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                onClick={onClose}
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
              >
                Cancel
              </button>

              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddReimbursement;
