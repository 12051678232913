/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import apiService from "services/apiService";
import NoDataImage from "assets/nodata.jpg";
import { dateFormate } from "util/Util";

const History = ({ row }: any) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    apiService
      .get(`api/assets/history/${row.id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [row.id]);

  return (
    <div className="p-2">
      <div className="overflow-x-auto">
        {data.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Model
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assigned To
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Assign Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Release Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Identity
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{dateFormate(new Date(item.purchaseDate))}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.model}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.assign}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.location}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{dateFormate(new Date(item.assignDate))}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {item.releaseDate ? dateFormate(new Date(item.releaseDate)) : "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.identity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="center">
            <img src={NoDataImage} className="no-data-img" loading="lazy" alt="No data" />
          </div>
        )}
      </div>
    </div>
  );
};

export default History;
