/* eslint-disable */
import Input from "components/Input";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import apiService from "services/apiService";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ImageA from "assets/nodata.jpg";
const validationSchema = yup.object({
  status: yup.string().required("status required"),
  comment: yup.string().required("status required"),
});

export default function ApproveTimesheet({ projects, setApprove, selectedUser, currentYear, currentMonth }: any) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [timesheet, setTimesheet] = useState<any>();
  const [projectData, setProjectData] = useState<any>();
  const { t } = useTranslation();
  const handleProjectChange = (selectedOption: any) => {
    setSelectedProject(selectedOption.value);
  };
  const projectOptions: { value: any; label: any }[] =
    projects && projects.length > 0
      ? projects.map((project: any) => ({
          value: project.projectId,
          label: project.name,
        }))
      : [];
  const getTimeSheetData = (payload: any) => {
    const queryParams = new URLSearchParams({
      year: payload.year.toString(),
      month: payload.month,
      ...(payload.projectId && { projectId: payload.projectId.toString() }),
      ...(payload.email && { email: payload.email }),
    });
    const url = `api/timesheet?${queryParams.toString()}`;
    return apiService.get(url);
  };
  useEffect(() => {
    const payload = {
      year: currentYear,
      month: currentMonth,
      email: selectedUser,
      projectId: selectedProject,
    };
    // if (selectedProject) {
    getTimeSheetData(payload).then((res) => {
      // console.log("res :>> ", res.data[0]);
      setTimesheet(res?.data[0]);
      setProjectData(res.data[0]?.data[0]);
      // console.log("res.data[0].data[0]", res.data[0].data[0]);
      methods.setValue("status", res.data[0]?.status);
      methods.setValue("comment", res.data[0]?.comment);
    });
    // }
  }, [selectedProject]);
  const onSubmit = (data: any) => {
    const newData = { ...timesheet, ...data };
    console.log("data", newData);
    apiService.put(`api/timesheet/${newData.id}`, newData).then((val) => {
      console.log("updated Data :>> ", val);
      setApprove(false);
    });
  };
  return (
    <div>
      {timesheet ? (
        <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
          <div className="flex justify-center hidden">
            <div className="me-2 w-64	mb-2 " style={{ zIndex: 99 }}>
              <Select
                name="project"
                options={projectOptions}
                value={projectOptions.find((option) => option.value === selectedProject)}
                onChange={handleProjectChange}
                placeholder={t("timesheet.selectproject")}
                className="select"
              />
            </div>
          </div>

          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* <h2 className="text-2xl font-bold mb-4">Timesheet Information</h2> */}
              {/* <div className="mb-2">
                <strong>ID:</strong> {timesheet.id}
              </div> */}
              <div className="mb-2">
                <strong>Email:</strong> {timesheet.email}
              </div>
              <div className="mb-2">
                <strong>Year:</strong> {timesheet.year}
              </div>
              <div className="mb-2">
                <strong>Month:</strong> {timesheet.month}
              </div>

              <div className="mb-2">
                <strong>Project IDs:</strong> {timesheet.projectIds}
              </div>

              {/* <div className="mb-2">
                <strong>Submit Date:</strong> {new Date(timesheet.submitDate).toLocaleString()}
              </div> */}
              <div className="mb-2">
                <strong>Total Hours:</strong> {timesheet.totalHour}
              </div>
              <div className="mb-2">
                <label htmlFor="status" className="block text-gray-700 font-bold mb-2">
                  Status
                </label>
                <select
                  id="status"
                  {...methods.register("status", { required: true })}
                  className={`block w-full mt-1 p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 `}
                >
                  <option value="InProgress">In Progress</option>
                  <option value="Submitted">Submitted</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Resubmitted">Resubmitted</option>
                  <option value="ReOpen">Re Open</option>
                </select>
              </div>

              <div className="mb-2">
                <Input label="Comments" name="comment" type="textarea" />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      ) : (
        <div className="flex justify-center">
          <img src={ImageA} alt="" height={250} width={250} loading="lazy" />
        </div>
      )}
    </div>
  );
}
