/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
import React, { useState } from "react";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";
import Tooltip from "components/Tooltip";
import Modal from "components/Modal";
import UpdateProfile from "./UpdateProfile";
import GenericToaster from "components/Toaster";
import { FcEditImage } from "react-icons/fc";
import appConfig from "services/apiConfig";
import Image from "assets/avatar.png";
import { dateFormate } from "util/Util";

export default function ProfileDetails({ user, setRefresh }: any) {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const BreadCrumbList = [
    { name: "profile", link: "#" },
    { name: "view", link: "#" },
  ];
  const handleClick = () => {
    setOpenModal(true);
  };
  const [toastData, setToastData] = useState<{ message: string; severity: "info" | "error" } | null>(null);
  const udpateProfileImage = (e: any) => {
    const tokenValue = window.localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    if (user)
      fetch(appConfig.getBaseUrl() + "api/image/" + user?.id, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + tokenValue,
        },
      })
        .then((res) => {
          if (res.status === 401) {
            window.localStorage.removeItem("accessToken");
          } else return res.json();
        })
        .then((res) => {
          console.log("User Profile Image : ", res);
          setRefresh("updated");
        })
        .catch((err) => {
          console.log("User Profile Image Not upload : ", err);
        });
  };
  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <div>
        <div className="flex justify-end">
          <Tooltip content="Click to Update">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={handleClick}
            >
              <span className="">Update Profile</span>
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="container mx-auto py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* User Profile Card */}
          <div className="bg-white rounded-lg overflow-hidden shadow-lg">
            <div className="flex items-center justify-center bg-gray-300 h-80">
              <img
                src={user.imageUrl || Image}
                alt="User Image"
                className="w-48 h-48 rounded-full border-4 border-white"
                loading="lazy"
              />
              <label
                htmlFor="profileImageInput"
                className=" relative text-white-700 edit-button  hover:text-blue font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-white-500 dark:text-white-500 dark:hover:text-white dark:focus:ring-white-800 dark:hover:bg-white-500"
              >
                <input
                  id="profileImageInput"
                  type="file"
                  accept="image/jpeg,image/jpg,image/png,image/bmp,image/tiff"
                  title="Click here for update profile Photo"
                  onChange={udpateProfileImage}
                  className="hidden"
                />
                <FcEditImage size={25} />
              </label>
            </div>
            <div className="p-6 text-center">
              <h2 className="text-2xl font-semibold text-gray-800">
                {t("profile.displayName", { displayName: user.displayName })}
              </h2>
              <p className="text-gray-500">{t("profile.designation", { designation: user.designation })}</p>
            </div>
          </div>

          {/* User Information Card */}
          <div className="bg-white rounded-lg overflow-hidden shadow-lg mb-4 border border-gray-200 dark:bg-gray-700 dark:border-gray-700">
            <div className="p-6">
              <h2 className="text-2xl dark:text-white font-semibold text-gray-800 mb-4">{t("profile.userInfo")}</h2>

              {/* User Details Table */}
              <table className="w-full table table-compact table-striped truncate text-ellipsis">
                <tbody>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.emailText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">{t("profile.email", { email: user.email })}</td>
                  </tr>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.mobileText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">
                      {t("profile.mobile", { mobile: user.mobileNumber })}
                    </td>
                  </tr>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.officeText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">
                      {t("profile.office", { office: user.ofcLocation })}
                    </td>
                  </tr>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.dobText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">{dateFormate(user.dob)}</td>
                  </tr>
                  {/* <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.doj")}</td>
                    <td className="text-gray-600 dark:text-gray-300">
                      {moment(user.doj).format("DD-MMM-YYYY").toUpperCase()}
                    </td>
                  </tr> */}
                </tbody>
              </table>
              <h2 className="text-2xl dark:text-white font-semibold text-gray-800 mb-4 mt-4">{t("profile.contact")}</h2>
              <table className="w-full table table-compact table-striped truncate text-ellipsis">
                <tbody>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t(`profile.addressText`)}</td>
                    <td className="text-gray-600 dark:text-gray-300">
                      <div>
                        {t(`profile.address`, {
                          address: user.permanentAddress,
                        })}
                      </div>
                    </td>
                  </tr>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.cityText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">{t("profile.city", { city: user.city })}</td>
                  </tr>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.stateText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">{t("profile.state", { state: user.state })}</td>
                  </tr>
                  <tr className="mb-5">
                    <td className="font-semibold dark:text-white">{t("profile.pincodeText")}</td>
                    <td className="text-gray-600 dark:text-gray-300">
                      {t("profile.pincode", { pincode: user.pinCode })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* User Projects Section */}
        <div className="mt-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">{t("profile.projectText")}</h2>

          {/* User Projects Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {user.userProjectWithAssignedDate &&
              user.userProjectWithAssignedDate.length > 0 &&
              user.userProjectWithAssignedDate.map((project: any) => (
                <div
                  key={project.projectId}
                  className="bg-white rounded-lg overflow-hidden shadow-lg mb-4 border border-gray-200 dark:bg-gray-700 dark:border-gray-700"
                >
                  <div className="p-6 ">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2 dark:text-white">
                      {/* {t("profile.projectName", { projectName: project.name })} */}
                      {project.name}
                    </h3>
                    <p className="text-gray-500 mb-4 dark:text-gray-100">
                      {/* {t("profile.projectDetails", {
                        projectDetails: project.projectDetail,
                      })} */}
                      {project.projectDetail}
                    </p>
                    <div className="flex items-center justify-between">
                      <span className="text-gray-600 dark:text-gray-300">
                        {/* {t("profile.managerText")}: {t("profile.manager", { manager: project.manager })} */}
                        Manager : {project.manager}
                      </span>
                      <span className="text-gray-600 dark:text-gray-300">
                        {t("profile.teamText")}: {t("profile.team", { team: project.teamSize })}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)} header="Edit Profile Details">
          <UpdateProfile
            data={user}
            onClose={() => setOpenModal(false)}
            setToastData={setToastData}
            setRefresh={setRefresh}
          />
        </Modal>
      )}
      {toastData && (
        <GenericToaster
          open={true}
          message={toastData.message}
          severity={toastData.severity}
          onClose={() => setToastData(null)}
        />
      )}
    </>
  );
}
