import React from "react";

interface CardProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  header?: string;
  footer?: string;
  style?: React.CSSProperties; // Added this line
}

export default function Card({
  children,
  title,
  subtitle,
  imageUrl,
  header,
  footer,
  style, // Destructured style prop
  ...rest
}: CardProps) {
  return (
    <div
      className=" bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-700 dark:border-gray-700"
      style={style} // Applied style prop
      {...rest}
    >
      {header && (
        <div className="bg-gray-100 border-b rounded-lg py-3 px-4 md:py-4 md:px-5 dark:bg-slate-900 dark:border-gray-700">
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">{header}</p>
        </div>
      )}

      {imageUrl && <img className="rounded-t-lg" src={imageUrl} alt="." loading="lazy" />}
      <div className="p-5">
        {title && <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{title}</h5>}
        {subtitle && <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">{subtitle}</p>}
        {children}
      </div>
      {footer && (
        <div className="bg-gray-100 rounded-lg py-3 px-4 md:py-4 md:px-5 dark:bg-slate-900 dark:border-gray-700">
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">{footer}</p>
        </div>
      )}
    </div>
  );
}
