/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import apiService from "services/apiService";
import Input from "components/Input";
// import { min } from "moment";

const schema = yup.object().shape({
  /**
   * Validating and Avoided Spacial Character for Input Fields such as mobilenumber, State, Pan Card , City And PinCode  
   */
  mobileNumber : yup.number().integer().positive()
  .test("len","phone Number Should be 10 digits",
    val => val?.toString().length === 10
  ),

  state : yup.string().matches(/[a-zA-Z0-9]/, '* This field cannot contain white space and special character'),
  permanentAddress : yup.string().matches(/[a-zA-Z0-9]/, '* This field cannot contain white space and special character'),
  tempAddress :yup.string().matches(/[a-zA-Z0-9]/, '* This field cannot contain white space and special character'),
 
  pan :yup.string()
  .test("len","It Should be 10 Digit alphanumeric", 
    val =>val?.length ===10 
  ).matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain white space and special character'),
  
  city : yup.string().matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain white space and special character'),
  
  pinCode :yup.number()
  .test("len","Pin Code Must be 6 Digits",
    val =>val?.toString().length===6),
 
    displayName: yup.string().required(),
 
  dob: yup
    .string()
    .required()
    .test("age", "You must be at least 18 years old", (value) => {
      if (!value) return false;
      const today = new Date();
      const birthDate = new Date(value);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= 18;
    }),

 
  
});

interface updateProps {
  onClose: () => void;
  data: any;
  setToastData: any;
  setRefresh: any;
}
export default function UpdateProfile({ onClose, data, setToastData, setRefresh }: updateProps) {
  const methods = useForm({ resolver: yupResolver(schema), defaultValues: data });
 console.log("data", data);
 
 const onSubmit: SubmitHandler<any> = (data: any) => {
  
   apiService
     .put("api/user", data)
     .then(() => {
       setToastData({ message: "Data updated successfully!", severity: "info" });
       setRefresh("1");
      console.log("api/user" + data);
     })
     .catch(() => {
       setToastData({ message: "Error updating data. Please try again.", severity: "error" });
     })
     .finally(() => {
       onClose();
     });
 };
 useEffect(() => {
   if (data.dob) methods.setValue("dob", new Date(data.dob).toISOString().split("T")[0] || "");
 }, []);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="grid gap-4 grid-cols-2">
          <Input name="displayName" label="Full Name" placeholder={`Enter Full Name`} disabled={true} />
          <Input name="dob" label="Date Of Birth" type="date" />
          <Input name="mobileNumber" label="Mobile Number" placeholder={`Enter Mobile Number`} type="number" required />
          <Input
            name="emergencyMobileNumber"
            label="Emergency Mobile Number"
            placeholder={`Enter Mobile Number`}
            type="number"
          />
          <Input name={`tempAddress`} label="Temp Address " placeholder={`Enter Address`} type="textarea" />
          <Input
            name="permanentAddress"
            label="Permanent Address "
            placeholder={`Enter Permanent Address`}
            type="textarea"
          />
          <Input name="city" label="City" placeholder={`Eg: Bengaluru`} />
          <Input name="state" label="State" placeholder={`Eg: Karnataka`} />
          <Input name="pinCode" label="Pincode" placeholder={`Eg : 606567`} type="number" required />
          <Input name="pan" label="Pan Card" placeholder={`Enter Pancard Number`} />
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white px-8 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
          >
            Submit
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
