/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { FaAngleLeft, FaAngleRight, FaSortUp, FaSortDown } from "react-icons/fa";
import { AiOutlineExport } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import "./DataTable.css";
import { dateFormate } from "util/Util";
import Loader from "components/Loader";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import NoDataImage from "assets/nodata.jpg";

export interface Column {
  bold?: boolean;
  key: string;
  label: string;
  actions?: (row: Row) => React.ReactNode;
  excludeExport?: boolean;
  type?: string;
  render?: (value: ReactNode, row: Row) => React.ReactNode;
  className?: React.CSSProperties;
}

export interface Row {
  id: number;
  [key: string]: ReactNode;
}

export interface DataTableProps {
  data: Row[];
  columns: Column[];
  title?: string;
  onAddBtnClick?: () => void;
  loading?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({ data, columns, title = "", onAddBtnClick, loading = false }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [paginatedData, setPaginatedData] = useState<Row[]>([]);
  const [filteredData, setFilterData] = useState<Row[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const { t } = useTranslation();

  useEffect(() => {
    setSearchTerm("");
  }, [data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  useEffect(() => {
    let updatedData = [...data];

    // Apply search filter
    if (searchTerm) {
      updatedData = updatedData.filter((row) =>
        Object.values(row).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    // Apply sorting
    if (sortColumn) {
      updatedData.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;
        if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
        if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilterData(updatedData);
  }, [searchTerm, sortColumn, sortDirection, data]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(filteredData.slice(startIndex, endIndex));
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    setTotalPages(totalPages);
  }, [currentPage, itemsPerPage, filteredData]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(totalPages, prevPage + 1));
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    const exportColumns = columns.filter((column) => !column.excludeExport);
    const columnsKeys = exportColumns.map((column) => column.key);
    const rows = data.map((data1) => columnsKeys.map((key) => data1[key]));
    worksheet.addRow(columnsKeys);
    rows.forEach((row) => worksheet.addRow(row));
    const blob = await workbook.xlsx.writeBuffer();
    const fileName = title ? `${title}.xlsx` : `exported_data.xlsx`;
    saveAs(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      fileName
    );
  };

  const handleAddClickEvent = () => {
    if (onAddBtnClick) onAddBtnClick();
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handleSort = (key: string) => {
    if (sortColumn === key) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(key);
      setSortDirection("asc");
    }
  };

  const renderSortIndicator = (columnKey: string) => {
    if (sortColumn === columnKey) {
      return sortDirection === "asc" ? (
        <div className="center" style={{ marginBottom: -5, marginLeft: 5 }}>
          <FaSortUp />
        </div>
      ) : (
        <div className="center" style={{ marginTop: -5, marginLeft: 5 }}>
          <FaSortDown />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700">
        <div className="w-full mb-1">
          <div className="mb-4">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              {t("table.heading", { title: title })}
            </h1>
          </div>
          <div className="sm:flex">
            <div className="items-center hidden mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0 dark:divide-gray-700">
              <form className="lg:pr-3" action="#" method="GET">
                <label htmlFor="users-search" className="sr-only">
                  Search
                </label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <input
                    type="text"
                    name="email"
                    id="users-search"
                    className="search-input"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </form>
            </div>
            <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
              {onAddBtnClick && (
                <Tooltip content="Click to add">
                  <button
                    onClick={handleAddClickEvent}
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <MdOutlineAddCircleOutline size={18} style={{ marginRight: 5 }} />
                    {t("table.heading", { title: "Add" })}
                  </button>
                </Tooltip>
              )}
              {filteredData.length || data.length ? (
                <Tooltip content="Click to export">
                  <button
                    onClick={handleExportToExcel}
                    className="inline-flex items-center py-2.5 px-5 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    <AiOutlineExport size={18} style={{ marginRight: 5 }} />
                    Export
                  </button>
                </Tooltip>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          {loading ? (
            <Loader />
          ) : (
            <div className="inline-block w-full align-middle">
              {paginatedData.length > 0 ? (
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                    <thead className="bg-gray-100 dark:bg-gray-700">
                      <tr>
                        {columns.map((column) => (
                          <th
                            key={column.key}
                            scope="col"
                            className="p-4 text-xs font-medium text-left text-gray-500 uppercase cursor-pointer dark:text-gray-400"
                            onClick={() => handleSort(column.key)}
                          >
                            <div className="flex items-center">
                              {t("table.columnlabel", {
                                columnlabel: column.label,
                              })}
                              {renderSortIndicator(column.key)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                      {paginatedData.map((row, index) => (
                        <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                          {columns.map((column, index1) => (
                            <td
                              scope="row"
                              className={` ${column.bold ? "font-semibold text-gray-900" : "text-gray-500"} ${
                                column.className || ""
                              } max-w-sm p-4 overflow-hidden text-base font-normal text-gray-500 truncate xl:max-w-xs dark:text-gray-400`}
                              key={index1}
                            >
                              {column.key === "actions" ? (
                                column.actions?.(row)
                              ) : column?.type === "Date" ? (
                                <strong
                                  className={` ${
                                    column.bold ? "font-semibold text-gray-900" : "text-gray-500"
                                  }   ${column.className || ""} max-w-sm  overflow-hidden text-base font-normal text-gray-500 truncate xl:max-w-xs dark:text-gray-400`}
                                >
                                  {dateFormate(row[column.key] + "")}
                                </strong>
                              ) : column.render ? (
                                column.render(row[column.key], row)
                              ) : column.key === "id" ? (
                                <strong>
                                  #
                                  {currentPage === 1
                                    ? `${currentPage + index}`
                                    : (currentPage - 1) * itemsPerPage + index + 1}
                                </strong>
                              ) : (
                                row[column.key]
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="center">
                  <img src={NoDataImage} className="no-data-img" loading="lazy" alt="No data" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {paginatedData.length > 0 && (
        <div className="sticky bottom-0 right-0 items-center w-full p-4 bg-white border-t border-gray-200 sm:flex sm:justify-between">
          <div className="flex items-center mb-0 sm:mb-0">
            <select id="small" className="p-2 mb-0 text-sm pages" onChange={handleItemsPerPageChange} defaultValue="10">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span className="counting text-gray-500 ml-2">
              Showing{" "}
              <span className="counting text-gray-900 ">
                {totalPages > 0
                  ? `${currentPage * itemsPerPage - itemsPerPage + 1}-${currentPage === totalPages ? filteredData.length : currentPage * itemsPerPage}`
                  : "0"}
              </span>{" "}
              out of <span className="counting text-gray-900 ">{filteredData.length}</span>
            </span>
          </div>
          <div className="flex items-center mb-4 sm:mb-0"></div>
          <div className="flex items-center space-x-3">
            {currentPage !== 1 && (
              <Tooltip content="Click to go Previous">
                <button onClick={handlePrevPage} className="action-btn btn-primary px-3 py-2 mr-1 rounded-lg">
                  <FaAngleLeft />
                  Previous
                </button>
              </Tooltip>
            )}
            {currentPage * itemsPerPage < filteredData.length && (
              <Tooltip content="Click to go Next">
                <button onClick={handleNextPage} className="action-btn btn-primary px-3 py-2 rounded-lg">
                  Next
                  <FaAngleRight className="ml-1" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DataTable;
