/* eslint-disable */
import React from "react";
import Image from "assets/avatar.png";
import { dateFormate } from "util/Util";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EmployeeDetails = ({ data }: any) => {
  return (
    <div className="flex flex-col space-y-4 bg-white rounded-lg shadow-md px-6 py-8">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-gray-800">{data.displayName}</h2>
        <img
          src={data.imageUrl || Image}
          alt="Employee profile picture"
          className="w-20 h-20 rounded-full border border-gray-200 object-cover"
          loading="lazy"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Email:</p>
          <p className="text-lg text-gray-500">{data.email}</p>
        </div>
        {/* <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">ID:</p>
          <p className="text-lg text-gray-500">{data.employeeId}</p>
        </div> */}
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Designation:</p>
          <p className="text-lg text-gray-500">{data.designation}</p>
        </div>
        {/* <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Department:</p>
          <p className="text-lg text-gray-500">{data.department}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Manager Name:</p>
          <p className="text-lg text-gray-500">{data.managerName}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Remaining Leave:</p>
          <p className="text-lg text-gray-500">{data.remainingLeave}</p>
        </div> */}
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Mobile Number:</p>
          <p className="text-lg text-gray-500">{data.mobileNumber}</p>
        </div>
        <div className="flex flex-col space-y-2">
          <p className="text-sm font-semibold text-gray-700">Join Date:</p>
          <p className="text-lg text-gray-500">{data && data.doj && dateFormate(data.doj)}</p>
        </div>
      </div>
      {data.userProjectWithAssignedDate && (
        <div className="mt-4">
          <h3 className="text-base font-semibold text-gray-500">Projects Assigned:</h3>
          <div className="flex flex-wrap">
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {data.userProjectWithAssignedDate &&
              data.userProjectWithAssignedDate.map((project: any) => (
                <span
                  className="bg-blue-100 m-2.5 text-blue-800 text-sm font-semibold  px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                  key={project.name}
                >
                  {project.name}
                </span>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeDetails;
