import Avatar from "components/Avatar";
import Card from "components/Card";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { GetTaskResponse } from "services/Task/taskInterface";
import taskManager from "services/Task/taskManager";
import { FcClock } from "react-icons/fc";
import moment from "moment";
import Tooltip from "components/Tooltip";
import { BreadCrumb } from "components/BreadCrumb";
import { useTranslation } from "react-i18next";

const initialStatuses = ["Todo", "In-progress", "Closed"];

const KanbanBoard = () => {
  const [tasks, setTasks] = useState<GetTaskResponse[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    taskManager.getAllTasks().then((res) => {
      setTasks(res.data);
      console.log("res.data :>> ", res.data);
    }).catch(err => {
      console.log('tasks error,', err);
    });
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const updatedTasks = [...tasks];
    const draggedTask = updatedTasks.find(
      (task) => task.taskId.toString() === draggableId
    );
    if (draggedTask) {
      draggedTask.status = destination.droppableId;
    }

    setTasks(updatedTasks);
  };
  const handleUpdate = () => {
    // const updateTask: GetTaskResponse[] = tasks;
    // if (updateTask) {
    //   taskManager
    //     .updateTask(updateTask)
    //     .then((res) => {
    //       console.log("res", res);
    //     })
    //     .catch((err) => {
    //       console.log("err :>> ", err);
    //     });
    // }
  };
  const BreadCrumbList = [
    { name: "tasks", link: "#" },
    { name: "list", link: "#" },
  ];
  return (
    <>
      <BreadCrumb BreadCrumbList={BreadCrumbList} />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex justify-end">
          <Tooltip content="Click to Update">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={handleUpdate}
            >
              <span className="">{t("task.updatetask")}</span>
            </button>
          </Tooltip>
        </div>
        <div className="flex  space-x-4 p-4">
          {initialStatuses.map((status) => (
            <Droppable key={status} droppableId={status} type="group">
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {(provided: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="p-4"
                  style={{ backgroundColor: "#F4F5F7", minWidth: "380px" }}
                >
                  <h2 className="text-lg font-bold mb-4 ">{status}</h2>
                  <hr />
                  {tasks
                    .filter((task) => task.status === status)
                    .map((task, index) => (
                      <Draggable
                        key={task.taskId}
                        draggableId={task.taskId.toString()}
                        index={index}
                      >
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {(provided: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className=" mb-4 rounded-md"
                          >
                            <Card>
                              <h3 className="text-gray-600  text-lg font-semibold dark:text-gray-400">
                                {task.taskDetail}
                              </h3>
                              <div className="mt-2">
                                <div className="flex flex-row items-center">
                                  <FcClock size={25} />{" "}
                                  <p className="text-gray-400 p-1 font-semibold dark:text-gray-400">
                                    {moment(task.startDate).format(
                                      "DD-MM-YYYY"
                                    )}
                                    :{" "}
                                    {moment(task.dueDate).format("DD-MM-YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className="flex justify-between items-center	p-1 mt-1">
                                <p className="text-gray-800   font-semibold dark:text-gray-400">
                                  {t("task.statusText")}: {task.status}
                                </p>
                                <Avatar
                                  name={task.assignedTo}
                                  bgColor="gray-100"
                                  textColor="blue-800"
                                  size="10"
                                />
                              </div>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </>
  );
};

export default KanbanBoard;
