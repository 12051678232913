import React from "react";
interface AvatarProps {
  name?: string;
  imageUrl?: string;
  bgColor: string;
  textColor: string;
  size?: string;
}
const Avatar = ({ name, imageUrl, bgColor, textColor, size }: AvatarProps) => {
  const initials = name ? name.slice(0, 1).toUpperCase() : "";
  const avatarStyle = `relative inline-flex items-center justify-center w-${size} h-${size} overflow-hidden bg-${bgColor} text-${textColor} rounded-full`;

  return (
    <div className={avatarStyle}>
      {imageUrl ? (
        <img src={imageUrl} alt="avatar" className="w-full h-full object-cover" loading="lazy" />
      ) : (
        <span className="font-medium">{initials}</span>
      )}
    </div>
  );
};

export default Avatar;
