import DataTable, { DataTableProps } from "components/DataTable";
import React, { useEffect } from "react";

const HolidaysList: React.FC<DataTableProps> = ({ data, columns, loading }) => {
  useEffect(() => {
    console.log("data, columns ", data, columns);
  }, []);
  return <DataTable data={data} columns={columns} title="Holidays List " loading={loading} />;
};
export default HolidaysList;
