/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { dateFormate } from "util/Util";

const ProjectDetail = ({ data }: any) => {
  const project = data;
  return (
    <div className=" rounded-xl overflow-hidden shadow-md">
      <div className="md:flex">
        <div className="p-4">
          <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
            Project Id: {project.projectId}
          </div>
          <h2 className="block mt-1 text-lg leading-tight font-medium text-black">
            {project.name || "Unnamed Project"}
          </h2>
          <p className="mt-2 text-gray-500">
            <span className="font-semibold">Manager:</span> {project.manager || "Not specified"}
          </p>
          <p className="mt-2 text-gray-500">
            <span className="font-semibold">Team Size:</span> {project.teamSize || "Not specified"}
          </p>
          <p className="mt-2 text-gray-500">
            <span className="font-semibold">Start Date:</span> {dateFormate(project.startDate) || "Not specified"}
          </p>
          <p className="mt-2 text-gray-500">
            <span className="font-semibold">Completion Date:</span>{" "}
            {dateFormate(project.completionDate) || "Not specified"}
          </p>
          <p className="mt-2 text-gray-500">
            <span className="font-semibold">Client Name & Contact:</span> {project.clientContactName || "Not specified"}
            {project.clientContactNumber && `, ${project.clientContactNumber}`}
          </p>
          <p className="mt-2 text-gray-500">
            <span className="font-semibold">Project Detail:</span> {project.projectDetail || "Not specified"}
          </p>
          <div className="mt-4">
            <span className="font-semibold">Emails:</span>{" "}
            {project.emails &&
              project.emails.split(",").map((email: string, index: number) => (
                <span key={index} className="text-blue-500">
                  {email.trim()}
                  {index < project.emails.split(",").length - 1 && ", "}
                </span>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
