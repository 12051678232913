/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import Input from "components/Input";
import reimbursementManager from "services/Reimbursement/reimbursementManager";
import { ReimbursementPayload, ReimbursementResponse } from "services/Reimbursement/reimbursementInterface";
import { dateFormate } from "util/Util";
import formatCurrency from "util/currency";

const validationSchema = yup.object({
  reimbursementType: yup.string().required("Reimbursement type is required"),
  status: yup.string().required("Status is required"),
  description: yup.string().required("Comment is required"),
  submitAmount: yup.number().notRequired(),
  appovedamount: yup
    .number()
    .required("Appoved amount is required")
    .typeError("Value must be a number")
    .positive("Value must be greater than zero")
    .min(1, "Approved amount must be at least 1")
    .test("is-valid-city", "Approved Amount should not exceed Submiited Amount ", async function (inputVal, context) {
      console.log(context);
      if (!inputVal) return false;

      const submittedAmount = localStorage.getItem("submittedAmount");
      // const sAmount = submittedAmount ? JSON.parse(submittedAmount) : null;
      if (submittedAmount !== null) {
        const sAmount = JSON.parse(submittedAmount as string);
        console.log(sAmount, inputVal);
        if (sAmount && inputVal && inputVal > sAmount) {
          return false;
        }
      }
      return true;
    }),
});

interface IProps {
  onClose: () => void;
  selectedRow: ReimbursementResponse | undefined;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const reimbursementType = [
  { value: "Travel", label: "Travel" },
  { value: "Office", label: "Office" },
  { value: "Hardware", label: "Hardware" },
  { value: "Software", label: "Software" },
  { value: "Party", label: "Party" },
];

export const status = [
  { value: "APPROVED", label: "Approved" },
  { value: "REJECTED", label: "Rejected" },
  { value: "PENDING", label: "Pending" },
];

const UpdateReimbursement = ({ onClose, selectedRow, onSuccess, onError }: IProps) => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [comment, setComment] = useState("");
  const [approvedAmount, setApprovedAmout] = useState(0);
  const [differenceAmount, setDifferenceAmount] = useState(0);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onReimbursementChange = (selectedOp: any) => {
    setSelectedType(selectedOp.value);
  };

  const onStatusChange = (selectedOp: any) => {
    setSelectedStatus(selectedOp.value);
  };

  const onCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  const onSubmit = (data: any) => {
    const submitAmout = selectedRow?.submitAmount;
    const differenceAmount1 = submitAmout! - data.appovedamount;
    const payload: ReimbursementPayload = {
      id: selectedRow?.id || 0,
      approveAmount: data.appovedamount || selectedRow?.approveAmount,
      comment: data.comment || selectedRow?.comment,
      description: selectedRow?.description || "",
      differenceAmount: differenceAmount1 || selectedRow?.differenceAmount || 0,
      lastModifiedDate: new Date().toISOString(),
      spentDate: selectedRow?.spentDate || "",
      status: data.status || selectedRow?.status,
      type: data.reimbursementType || selectedRow?.type,
      unit: selectedRow?.unit || "",
    };

    console.log("payload", payload);
    const dto_object = new Blob([JSON.stringify(payload)], {
      type: "application/json",
    });
    const formdata = new FormData();
    formdata.append("reimbursement", dto_object);
    reimbursementManager
      .updateReimbursement(formdata)
      .then((res) => {
        if (res.status === 200) {
          onSuccess("Updated successfully");
        } else {
          onError("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(`error updating ==> ${err}`);
        onError("Something went wrong");
      })
      .finally(() => {
        onClose();
      });
  };

  const onApprovedAmountChange = (e: any) => {
    const amount = e.target.value;
    const submitAmout = selectedRow?.submitAmount;
    const differenceAmount = submitAmout! - amount;
    setApprovedAmout(amount);
    console.log("differenceAmount", differenceAmount);
    setDifferenceAmount(differenceAmount);
  };

  useEffect(() => {
    if (selectedRow) {
      setSelectedType(selectedRow.type);
      setSelectedStatus(selectedRow.status);
      setComment(selectedRow.comment);
      setApprovedAmout(selectedRow.approveAmount);
      setDifferenceAmount(selectedRow.differenceAmount);
      localStorage.setItem("submittedAmount", selectedRow?.submitAmount.toString());
    }
  }, []);

  useEffect(() => {
    methods.setValue("reimbursementType", selectedType);
    methods.setValue("status", selectedStatus);
    methods.setValue("description", comment);
  }, [selectedStatus, selectedType, comment, methods, approvedAmount]);

  return (
    <div className="space-y-6 md:grid-cols-2">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="form-group grid grid-rows-5 gap-6">
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Reimbursement Type
                </label>

                <Select
                  options={reimbursementType}
                  className="select"
                  value={reimbursementType.find((op) => op.value === selectedType)}
                  onChange={onReimbursementChange}
                  name="reimbursementType"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Submit Amount
                </label>
                <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
                  {formatCurrency(selectedRow?.submitAmount, selectedRow?.unit)}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex flex-col">
                <Input
                  label="Approve Amount"
                  name="appovedamount"
                  type="number"
                  value={approvedAmount}
                  onChange={onApprovedAmountChange}
                  // max={selectedRow?.submitAmount}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Difference Amount
                </label>
                <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
                  {formatCurrency(differenceAmount, selectedRow?.unit) || 0}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div className="flex flex-col">
                <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Spent Date
                </label>
                <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
                  {selectedRow && dateFormate(selectedRow?.spentDate)}
                </p>
              </div>
              <div className="flex flex-col">
                <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Submit Date
                </label>
                <p className="bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300">
                  {selectedRow && dateFormate(selectedRow?.submitDate)}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2">
              <div className="flex flex-col">
                <label htmlFor="project" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Status
                </label>
                <Select
                  className="select"
                  options={status}
                  name="status"
                  value={status.find((op) => op.value === selectedStatus)}
                  onChange={onStatusChange}
                />
              </div>
              <div className="flex flex-col">
                <Input
                  type="text"
                  placeholder="Unit"
                  name="unit"
                  label="Unit"
                  disabled
                  value={selectedRow ? selectedRow.unit : "INR"}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <Input
                type="textarea"
                placeholder="Comment"
                name="comment"
                label="Reimbursement Comment"
                value={comment}
                onChange={onCommentChange}
              />
            </div>
            <div className="flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
              <button
                type="button"
                onClick={onClose}
                className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 ms-3"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default UpdateReimbursement;
